<template>
    <div>
        <zw-select-group v-model="inputVal.status"
                         :options="getTodoStatues()"
                         name="status"
                         :label-prefix="labelPrefix"
                         validate="required"
                         disable-label
        ></zw-select-group>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'TodoChangeStatusOptions',
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getTodoStatues']),
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>